export const navItemFields = `
title,
"navId": navId.current,
items[]{
  "slug": navigationItemUrl.internalLink->slug.current,
  "slugExternal": navigationItemUrl.externalUrl,
  text,
  subitems[] {
    "slug": navigationItemUrl.internalLink->slug.current,
    "slugExternal": navigationItemUrl.externalUrl,
    text,
  }
}`;