import React from 'react';
import { Link } from "react-router-dom";

export const PostList = ({posts}) => {
	
	const orderedPosts = posts.map(post => { post.date = new Date(post.publishedAt); return post; }).sort((a, b) => { 
		return new Date(b.date) - new Date(a.date)
	});

	const formatDate = (date) => {
		return date.toLocaleDateString("nb-NO");
	};

	return posts && (
		<div className="row py-4">
			{ !!posts && orderedPosts.map((post, index) => (
				<div className={`col${(posts.length > 2)?'-lg-4 col-md-6 mb-4':''}`} key={`postitem-${index}`}>
					<div className="card text-bg-dark" style={{ height: 256 }}>
						<Link to={"/posts/" + post?.slug?.current} key={post?.slug?.current}>
							<img src={post?.mainImage?.asset?.url} className="card-img" alt="" />
							<div className="card-img-overlay">
								<h2 className="card-title"><span className="published">{formatDate(post.date)}</span><span className="title">{post?.title}</span></h2>
							</div>
						</Link>
					</div>
				</div>
			))}
		</div>
	);	
}