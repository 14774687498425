import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import AllPosts from "./components/AllPosts.js";
import Home from './components/Home';
import Page from "./components/Page";
import OnePost from "./components/OnePost.js";
import sanityClient from "./client.js";
import SiteConfigQuery from './graphql/queries/SiteConfig';

import { HelmetProvider } from 'react-helmet-async';
const helmetContext = {};

function App() {
  const [config, setConfig] = useState({});

  useEffect(() => {
    sanityClient
      .fetch(SiteConfigQuery)
      .then((data) => { setConfig(data); })
      .catch(console.error);
  }, []);
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <>
          <Route render={routeProps => (
              <Home routeProps={routeProps} config={{...config}} />
            )} 
            path="/" 
            exact/>
          <Route 
            path="/posts" 
            render={routeProps => (
              <AllPosts routeProps={routeProps} config={{...config}} />
            )}
            exact />
          <Route 
            render={routeProps => (
              <OnePost routeProps={routeProps} config={{...config}} />
            )} 
            path="/posts/:slug" 
          exact/>
          <Route 
            render={routeProps => (
              <Page routeProps={routeProps} config={{...config}} />
            )} 
            path="/:pageslug" 
            exact/>
        </>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
