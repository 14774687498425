import { bodyFields, imageFields } from './';
export const postFields = `
_id,
title,
slug,
highlighted,
author->{
	name,
	slug,
	image,
	bio
},
mainImage{
	${imageFields}
},
category->{
	title,
	description
},
_createdAt,
_updatedAt,
publishedAt,
body[]{
	${bodyFields}
}`;