import React from "react";
import { useParams } from "react-router-dom";

export const Header = ({config}) => {
	const { slug, pageslug } = useParams();
	const currentSlug = slug ? slug : pageslug;

	
	if(config.mainNav === undefined)
		return false;

	if(process.env.NODE_ENV === 'development')
		config.url = 'http://localhost:3000/';

	
	return (
		<header className="pt-3">
			{ config?.themecolor && 
				<style type="text/css" dangerouslySetInnerHTML={{__html:`
					.nav-underline .nav-link {
						color: black !important;
					}
					.nav-link:focus, .nav-link:hover, .nav-underline .nav-link.active, .nav-underline .show > .nav-link, a {
						color: ${config.themecolor} !important;
					}
					.social-nav a i {
						color: ${config.themecolor} !important;
						font-size: 24px;
					}
				`}}></style>
			}
			<div className="container">
				<div className="d-flex align-items-center justify-content-center flex-column pb-3">
					<a href="/" className="">
						<img width="80" src={config.logo.url} alt={config.title} />
					</a>
				</div>
			</div>
			<div className="container-fluid border-top border-bottom border-light-subtle">
				<div className="container-lg container-fluid">
					<div className="">
						<nav className="navbar navbar-expand-md">
							<button className="navbar-toggler collapsed float-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMain" aria-controls="navbarMain" aria-expanded="false" aria-label="Toggle navigation">
					        	<span className="navbar-toggler-icon"></span>
					    	</button>
					    	<div id="navbarMain" className="navbar-collapse collapse">
								<ul className="nav navbar-nav col-12  me-lg-auto mb-2 justify-content-center mb-md-0 nav nav-underline">
									<li className="nav-item">
										<a href={config.url} title="Hjem" className={"nav-link px-2" + ((currentSlug === undefined)?" active":"")}>Hjem</a>
									</li>
									{config.mainNav.items.map((navItem, i) => (
										<li key={`navitem-${i}`} className={(navItem.subitems ? 'dropdown':'')}>
											{ navItem.slug !== undefined ? (
												<a href={config.url + navItem.slug} title={navItem.text} className={"nav-link px-2" + ((currentSlug === navItem.slug)?" active":"")}>{navItem.text}</a>
											):(
												<a href={navItem.slugExternal} title={navItem.text} className={"nav-link px-2"}>{navItem.text}</a>
											)}
											{navItem?.subitems && navItem.subitems.length > 0 &&
												<ul className="dropdown-menu">
													{navItem.subitems.map(subItem => (
														<li key={subItem.slug} className="dropdown-submenu">
															{ subItem.slug !== undefined ? (
																<a href={config.url + subItem.slug} title={subItem.text} className={"nav-link px-2" + ((currentSlug === subItem.slug)?" active":"")}>{subItem.text}</a>
															):(
																<a href={subItem.slugExternal} title={subItem.text} className={"nav-link px-2"}>{subItem.text}</a>
															)}
														</li>
													))}
												</ul>
											}
										</li>
									))}
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</div>
			
	    </header>
	);	
};