import React from "react";
import { Header, Footer} from './';

export const Wrapper = ({config, hero, children}) => {
	return (
		<div className="wrapper">
			<Header config={config}/>
			{hero}
			<main className="container">
				{children}
			</main>
			<Footer config={config} />
		</div>
	);
}