import React from 'react';

export const Footer = ({config}) => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 px-0">
					<footer className="py-3 my-4 border-top">
						<div className="container">
							<div className="row">
								<div className="d-flex flex-wrap justify-content-between align-items-center">
									<div className="col d-flex align-items-center justify-content-between">
										<a href="/" className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
											Dræggeguttenes Forening
										</a>
										<span className="mb-3 mb-md-0 text-body-secondary">© 2023 Dræggeguttenes Forening</span>
									</div>
									{config.socialNav && 
										<ul className="nav col-md-4 justify-content-end list-unstyled d-flex social-nav">
										{config.socialNav.items.map((navItem, i) => (
											<li className="ms-3" key={`footer-navitem-${i}`}>
												{ navItem.slug !== undefined ? (
													<a href={config.url + navItem.slug} title={navItem.text} className="nav-link px-2 text-white">{navItem.text}</a>
												):(
													<a href={navItem.slugExternal} title={navItem.text} className="nav-link px-2 text-white" target="_blank" rel="noopener noreferrer"><i className={`bi bi-${navItem.text}`}></i></a>
												)}
											</li>
										))}
										</ul>
									}
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
			
		</div>
	);
}