import React from "react";
import { SEO, Hero, Wrapper, PostList } from './layout';
import serializers from '../utils/serializers';

const BlockContent = require('@sanity/block-content-to-react')

export default function Home({config}) {
  
  return (
    <Wrapper config={config} hero={<Hero title={'Dræggeguttenes Forening'} background={config?.frontpage?.mainImage?.asset?.url} />}>
      <SEO
        title={config.title}
        description={config.description}
        type={'page'}
      />
      { config?.frontpage?.highlighted !== undefined && config?.frontpage?.highlighted.length > 0 &&
        <div>
          <h3>Viktige nyheter</h3>
          <PostList posts={config?.frontpage?.highlighted || []} />
        </div>
      }
      { config?.frontpage?.posts !== undefined && config?.frontpage?.posts.length > 0 &&
        <div>
          <h3>Relaterte artikler</h3>
          <PostList posts={config?.frontpage?.posts || []} />
        </div>
      }        
      
      <div className="row">
        <div className="col-12 text-center">
          { config?.frontpage?.body !== undefined &&
            <BlockContent blocks={config.frontpage.body} serializers={serializers} />
          }
        </div>
      </div>
    </Wrapper>
  );
}
