import React from "react";
const BlockContent = require('@sanity/block-content-to-react')
const SlideShow = require('react-slideshow-image');
const Slide = SlideShow.Slide;

const serializers = {
  types: {
    block: (props) => {
      const { style = "normal" } = props.node;

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, "");
        return React.createElement(
          style,
          { className: `heading-${level}` },
          props.children
        );
      }

      if (style === "blockquote") {
        return <blockquote>- {props.children}</blockquote>;
      }

      // Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props);
    },
    notification: (props) => (
      <div className="alert alert-primary my-4" role="alert">{props.node.title}</div>
    ),
    code: (props) =>
      (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    file: (props) => {
      return (<a href={props.node.asset.url} title={props.node.asset.originalFilename} target="_blank" rel="noopener noreferrer" i>{props.node.asset.originalFilename}</a>);
    },
    contentItems: (props) => {
      return (
        <div className="container py-4">
          <div className="row">
            <h3>{props?.node?.title}</h3>
          </div>
          <div className="contentItems">
            { props?.node?.items?.map((item, i) => (
              <div className="row py-3 contentItem d-flex justify-content-center align-items-center" key={`navitem-${i}`}>
                <div className="col-5">
                  <img src={item?.image?.asset?.url} alt="" />
                </div>
                <div className="col-7">
                  <h3>{item?.title}</h3>
                  <p>{item?.content}</p>
                  <p><strong>{item?.subcontent}</strong></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    },
    iframe: (props) => (
      <iframe title={props?.node?._key} src={props?.node?.url || ''} width={props?.node?.width || 500} height={props?.node?.height || 372}></iframe>
    ),
    gallery: (props) => (
        <div className="gallery">
            <Slide easing="ease">
            {props.node.images.map((img, i) => {
                return (
                    <div key={"slide-"+i} className="each-slide">
                        <img src={img?.asset?.url} alt="" />
                    </div>
                )
            })}
            </Slide>
        </div>
    ),
  },
  file: (props) => {
    return (<a href={props.node.asset.url} title={props.node.asset.originalFilename} target="_blank" rel="noopener noreferrer" i>{props.node.asset.originalFilename}</a>);
  },

  list: (props) =>
    
    (props.type === "bullet" ? (
      <ul>{props.children}</ul>
    ) : (
      <ol>{props.children}</ol>
    )),
  listItem: (props) =>
    
    (props.type === "bullet" ? (
      <li>{props.children}</li>
    ) : (
      <li>{props.children}</li>
    )),
  marks: {
    strong: (props) =>
      <strong>{props.children}</strong>,
    em: (props) => <em>{props.children}</em>,
    code: (props) => <code>{props.children}</code>
  }
};

export default serializers;