import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import { SEO, Wrapper, PostList } from './layout';
import { useParams } from "react-router-dom";
import PageGroq from '../graphql/queries/Page';
import serializers from '../utils/serializers';
const BlockContent = require('@sanity/block-content-to-react');

export default function Page({config}) {
  const [allPageData, setAllPageData] = useState(null);

  const { pageslug } = useParams();
  
  

  useEffect(() => {
    sanityClient
      .fetch(PageGroq(pageslug))
      .then((data) => setAllPageData(data))
      .catch(console.error);
  }, [setAllPageData, pageslug]);

  

  return allPageData && pageslug !== 'posts' && (
    <Wrapper config={config}>
      <SEO
        title={`Dræggeguttenes Forening | ${allPageData?.title}`}
        description={allPageData.description}
        type={'page'}
      />
      <div className="row">
        <h2 className="h2 pt-4">{allPageData?.title}</h2>
      </div>
      {allPageData?.posts && allPageData.posts.length > 0 &&
        <PostList posts={allPageData?.posts || []} />
      }
        
      <div className="row">
        <div className="col-12 text-center">
          { allPageData.body !== undefined &&
            <BlockContent blocks={allPageData.body} serializers={serializers} />
          }
        </div>
      </div>
    </Wrapper>
  );
}
