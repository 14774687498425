export const bodyFields = `
...,
asset->{
    ...,
    "_key": _id
},
images[]{
    ...,
    asset->{
        ...,
        "_key": _id
    }
},
_type == "contentItems" => {
  title,
  items[] {
    ...,
    image {
      asset->{
          ...,
          "_key": _id
      }
    }
  }
}`;