import React from 'react';

export const Hero = ({title, text, image, background}) => {
	const bgImage = background !== undefined ? background : '';
	return background ? (
		<div className="container-fluid px-0">
			<div className="px-4 py-5 mb-3 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '340px' }}>
				{ title && 
					<h1 className="display-5 fw-bold text-white" style={{textShadow: '-1px -1px 1px #111, 2px 2px 1px #363636'}}>{title}</h1>
				}
				{ text && 
					<div className="col-lg-6 mx-auto">
						<p className="lead mb-4">{text}</p>
					</div>
				}	
			</div>
		</div>
	) : (
		<div className="container">
			<div className="row flex-lg-row-reverse align-items-center g-5 py-5">
				<div className="col-10 col-sm-8 col-lg-6">
					<img src={image} className="d-block mx-lg-auto img-fluid" alt={title} loading="lazy" width="700" height="500" style={{ maxHeight: '350px', objectFit: 'contain'}} />
				</div>
				<div className="col-lg-6">
					<h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">{title}</h1>
					{ text && 
						<p className="lead">{text}</p>
					}
				</div>
			</div>
		</div>
	)
}