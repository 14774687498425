import { postFields, bodyFields, imageFields } from './';

export const pageFields = `
...,
mainImage {
    ${imageFields}
},
"posts": [
    ...*[_type=='post' && _id in ^.posts[]._ref ]{
        ${postFields}
    },
    ...*[_type=='post' && category._ref in ^.relatedcategories[]._ref ]{
        ${postFields}
    }
],
relatedcategories[]->{
    _id,
    title,
    description,
},
body[]{
    ${bodyFields}
}`;