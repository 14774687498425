import { pageFields, postFields, navItemFields } from '../fragments';

const query = `*[_type == "siteConfig"][0]{
  title,
  description,
  url,
  "logo": logo.asset->{url},
  "themecolor": themeColor.hex,
  frontpage->{
    ${pageFields},
    "highlighted": *[_type=='post' && highlighted == true ] {${postFields}}
  },
  mainNav->{
    ${navItemFields}
  },
  footerNav->{
    ${navItemFields}
  },
  socialNav->{
    ${navItemFields}
  }
}`;

export default query;