import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import { SEO, Wrapper, PostList } from './layout';
import Posts from '../graphql/queries/Posts';

export default function AllPosts({config}) {
  const [allPostsData, setAllPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(Posts)
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <Wrapper config={config}>
      <SEO
        title={config.title}
        description={config.description}
        type={'page'}
      />
      <div>
        <h3 className="py-4">Alle artikler</h3>
        <PostList posts={allPostsData} />
      </div>

    </Wrapper>
  );
}
