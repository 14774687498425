import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import { SEO, Hero, Wrapper } from './layout';
import PostGroq from '../graphql/queries/Post';
import serializers from '../utils/serializers';
const BlockContent = require('@sanity/block-content-to-react')

export default function OnePost({config}) {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(PostGroq(slug))
      .then((data) => setPostData(data))
      .catch(console.error);
  }, [slug]);

  

  return postData && (
    <Wrapper config={config} hero={ <Hero title={postData.title} image={postData.mainImage.asset.url} /> }>
      <SEO
        title={`Dræggeguttenes Forening | ${postData?.title}`}
        description={postData?.description}
        type={'article'}
      />
      
      <div className="row">
        <div className="col-12 text-center">
          { postData.body !== undefined &&
            <BlockContent blocks={postData.body} serializers={serializers} />
          }
        </div>
      </div>
    </Wrapper>
  );
}
